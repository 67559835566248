<template>
    <div class="card sp-border-dark h-100">
        <div class="card-header sp-card-header">
            <strong>Guidance</strong>
            <div class="pull-right"></div>
        </div>
        <!-- /.panel-heading -->
        <div class="card-body">
            <div class="list-group mb-3">
                <div class="list-group-item"><strong class="text-bold">Specific Guidance</strong></div>

                <a @click="showTempusScenario(scenario.key)"
                    class="list-group-item d-flex justify-content-between align-items-center"
                    v-for="scenario in tempus_scenarios.active"
                    v-bind:key="scenario.key"
                    v-bind:class="'list-group-item-' + spm.overall.cssClass">
                    <span>{{ tempus_data.titles[scenario.key] }}</span>
                    <i class="fa fa-chevron-right"></i>
                </a>

                <div    v-if="!tempus_scenarios.active.length" 
                        class="list-group-item"
                        @click="ui.showAllScenarios = true"
                        >
                    <strong class="text-muted">No Specific Guidance</strong>
                    
                </div>
            </div>

            <div class="list-group">
                <div class="list-group-item"><strong class="text-bold">General Guidance</strong></div>
                <a @click="showTempusScenario(scenario.key)"
                    class="list-group-item d-flex justify-content-between align-items-center"
                    v-for="scenario in tempus_scenarios.general"
                    v-bind:key="scenario.key">
                   <span>{{ tempus_data.titles[scenario.key] }}</span>
                    <i class="fa fa-chevron-right"></i>
                </a>
            </div>

            <sc-modal :size="'lg'" v-if="ui.showTempusScenario" @close="ui.showTempusScenario = false">
                <span slot="title">Tempus - Moisture Scenario</span>
                <div slot="body">
                    <div>
                        <h2>{{ tempus_data.titles[ui.selectedTempusScenario] }}</h2>

                        <div v-for="cause in tempus_data.causes[ui.selectedTempusScenario]" :key="cause.title">
                            <h4>Cause: {{ cause.title }}</h4>
                            <blockquote>
                                {{cause.desc}}
                            </blockquote>
                            <p>
                            <strong>Read More: </strong>
                                <a v-for="kb in cause.kbs" @click="showTempusKB(kb)" :key="kb">{{kb}}&nbsp;|&nbsp;</a>
                            </p>
                        </div>
                    </div>
                </div>
            </sc-modal>


            <sc-modal :size="'md'" v-if="ui.showTempusKB" @close="ui.showTempusKB = false">
                <span slot="title">Tempus - Knowledge Base</span>
                <div slot="body">
                    <div id="KB-scroller" style="position:relative; max-height: 80vh; overflow-y: scroll">
                        <div v-for="(kb,id) in tempus_data.kb_items" :key="id">
                            <h1 v-bind:id="id">{{id}}</h1>
                            <h3>{{kb.title}}</h3>
                            <p>{{kb.desc}}</p>
                        </div>
                    </div>
                </div>
            </sc-modal>
        </div>
    </div>

</template>

<script>

export default {
    props: ['spm'],
    methods: {
        showTempusScenario(scenarioKey){
            var vm = this;
            vm.ui.showTempusScenario = true;
            vm.ui.selectedTempusScenario = scenarioKey
        }
        ,showTempusKB(KBid) {
            var vm = this;
            vm.ui.showTempusKB = true;
            setTimeout(function(){
                console.log(KBid, $("#KB-" + KBid))
                $("#KB-scroller").scrollTop($("#KB-scroller").scrollTop() + $("#KB-" + KBid).position().top)
            }, 500);
            //vm.ui.selectedTempusKB = KBid
        }
    }
    ,data: function(){
        return {
            tempus_scenarios: {
                active: [],
                general: []
            }
            ,
            ui: {
                showTempusScenario: false,
                showTempusKB: false,
                showAllScenarios:false
            }
            ,
            tempus_data: window.PSP.tempus.data
        }
    },
    created: function(){
        var vm = this;
        console.log("setting tempus scenarios from SPM")
        Vue.set(vm, 'tempus_scenarios', window.PSP.tempus.get_tempus_knowledge_status(vm.spm))
    }
    ,computed: {
    }
    ,
    watch: {
        "spm": function(){
            var vm = this;
            console.log("[watch] setting tempus scenarios from SPM")
            Vue.set(vm, 'tempus_scenarios', window.PSP.tempus.get_tempus_knowledge_status(vm.spm))
        }
    }
}





</script>

<style>
</style>
<template>

    <div class="row">
        <div class="col-12 text-center" style="padding: 1.2em; display: flex; justify-content: space-evenly; flex-wrap: wrap" v-if="tabbed">
            <button v-on:click="ui.showTab = grp.reason" class="btn btn-xs" v-bind:class="{'btn-secondary': ui.showTab == grp.reason, 'btn-light': ui.showTab != grp.reason}" v-for="grp in alarms" v-bind:key="grp.reason">
                <i class="fa fa-fw" v-bind:class="grp.icon"></i> 
                {{grp.name}} 
                <span class="badge badge-primary">{{grp.list.length}}</span>
            </button>
        </div>
       
        <div style="width:5px;"></div>
        <div class="col d-flex" v-for="grp in selected_reason" v-bind:key="grp.reason" :class="index === 0 ? 'px-0' : 'pl-1'" >
            <div class="card sp-border-dark flex-fill">
                <div class="card-header sp-card-header" v-if="!tabbed">{{grp.name}} </div>
                    <div class="card-body">
                        <div class="list-group list-group-flush" id="alarms-list" style="overflow-y: auto; max-height: 30rem;">
                            <div v-for="alarm in grp.list" class="list-group-item">  
                                <div class="row">    
                                    <div class="col-sm-6">                      
                                        <span v-bind:class="'text-' + alarm.cssClass">
                                            <i class="fa fa-fw" v-bind:class="alarm.icon"></i>
                                        </span>
                                        <i class="fa fa-fw text-muted" v-bind:class="alarm.typeIcon"></i>
                                    </div>
                                <div class="col-sm-6">
                                    <span class="badge pull-right" v-bind:title="alarm.time | moment('hh:mm:ss DD-MM-YYYY')">{{alarm.time | fromNow }}</span>
                                </div>
                                </div>
                                <div class="row">
                                <span v-if="grp.multiple_entities">
                                    <small><strong>[{{ names[alarm.oid] }}]</strong></small>&nbsp;
                                </span>
                                </div>
                                {{alarm.text}}
                            </div>
                            <div v-if="!grp.list.length" class="list-group-item"><em>No Alarm History</em></div>
                    </div>
                </div>
            </div>
        </div>
       
    </div>
</template>

<script>
export default {
        props: {
            device: Object, 
            tabbed: {
                type: Boolean,
                required: false,
                default: false
            },
            reason: {
                type: String,
                required: false,
            },
            module: {
                type: String,
                required: false,
            },
            modules: {
                type: Array,
                required: false,
            }
        }
        ,created: function(){
            this.loadAlarms();
        },
        computed: {
            selected_reason: function(){
                var vm = this;
                if(!vm.tabbed){
                    return vm.alarms
                } else {
                    return _.filter(vm.alarms, (al)=>{ return al.reason == vm.ui.showTab })
                }
            }
        },
        methods: {
            loadAlarms: function(){
                var vm = this;
                var ctx = this;
                var url = window.SMARTPLACES.urls.common.get_for(ctx.device).alarms + "?limit=30&grouped=true"

                if(vm.reason){
                    url = url + '&reason=' + vm.reason
                }
                else if(vm.module){
                    url = url + '&module=' + vm.module
                }
                else if(this.modules){
                    var modules_params = "&module=" + _.join(this.modules, '&module=')
                    url = url + modules_params
                }

                return axios.get(url)
                    .then(function(resp){
                        var raw = resp.data;
                        delete raw.fuel_poverty // erroneous bug, OK to delete.
                        
                        if(vm.reason && !raw[vm.reason]){
                            raw[vm.reason] = []; // if our one group doesn't exist, fake an empty one
                        }

                        var alarms = _.map(raw, (list, reason) => {

                            var model = SMART_PROPERTY.models[reason]

                            var multiple_entities = _.uniqBy(list, 'oid').length > 1

                            return {
                                reason: reason
                                ,multiple_entities: multiple_entities
                                ,list: SMART_PROPERTY.markupAlarms(list) // adds css labels and translates codes etc
                                ,name: model ? model.name : reason
                                ,icon: model ? model.icon : ""
                            }
                        })

                        return alarms
                    })
                    .then(function(data){
                        Vue.set(ctx, "alarms", data);

                        if(vm.tabbed && data.length){
                            vm.ui.showTab = data[0].reason
                        }
                    })
            }
            ,
            getNamesMap: function(){ // make a lookup table of the names of rooms or device nicknames
                var vm = this;
                var names = {};
                names[vm.device._id] = vm.device.nickname
                if(vm.device.devices){
                    // a property
                    _.each(vm.device.devices, function(d){
                        var room = _.get(d, 'housing.room')
                        names[d._id] = room || d.nickname
                    })
                }
                return names;
            }
        }
        ,data: function(){
            return {
                alarms: []
                ,
                ui: {
                    showTab: ''
                },
                names: this.getNamesMap()
            }
        }
    }
</script>

<style>
</style>
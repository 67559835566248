<template>
<div class="row">
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
        <div class="card sp-border-dark" >
            <div class="card-header sp-card-header">
                Photos
            </div>
            <!-- /.panel-heading -->
            <div class="card-body">
                <div style="position:relative; margin-right:1.2em" class="pull-left text-center" v-for="(photo,index) in photoSet" v-bind:key="photo.file">
                    <img class="img-thumbnail" v-bind:src="src_for_file('th_' + photo.file)"/><br>
                    <small><em>Uploaded: {{ photo.uploaded_on | fromNow }}</em></small>
                    <br>
                    <small>{{ !photo.comment ? '(no comment)' : photo.comment }}</small>
                    <div class="dropdown" style="position:absolute;top:5px;right:5px;background-color: white;border-radius: 4px;">
                        <button class="btn btn-outline-primary btn-sm dropdown-toggle" data-toggle="dropdown"><i class="fa fa-bars"></i></button>
                        <ul class="dropdown-menu">
                        <li class="dropdown-item"><a target="_blank" v-bind:href="src_for_file(photo.file)" ><i class="fa fa-fw fa-search-plus"></i> View</a></li>
                        <li class="dropdown-item"><a href="#" @click.prevent="editComment(device, photo, index)"><i class="fa fa-fw fa-edit"></i> Edit Comment</a></li>
                        <li class="dropdown-item"><a href="#" @click.prevent="delPhoto(device, photo, index)" ><i class="fa fa-fw fa-trash"></i> Delete</a></li>
                        <li class="dropdown-item" v-if="index != 0"><a href="#" @click.prevent="makePrimary(device, photo, index)"><i class="fa fa-fw fa-thumbs-o-up"></i> Make Primary</a></li>
                        </ul>
                    </div>
                </div>
                <button @click="ui.showPhotoUpload = true" class="btn btn-info btn-sm pull-right mt-3">Add Photo</button>
            </div>

            <sc-modal v-if="ui.showPhotoUpload" @close="ui.showPhotoUpload = false">
                <span slot="title">Choose Photo</span>
                <div slot="body">
                    <div id="photo_panel_ul_div" >
                        <form enctype="multipart/form-data">
                            <div class="form-group">
                                <div class="custom-file">
                                    <input type="file" ref="file" class="custom-file-input"  id="file" name="file" accept="image/*" >
                                    <label class="custom-file-label" for="customFile">Choose file</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="sr-only" for="comment">Comment</label>
                                <input type="text" class="form-control" id="comment" name="comment" v-model="newFileComment" placeholder="Comment">
                            </div>
                        </form>
                    </div>
                </div>
                <div slot="footer" class="sp-modal-footer">
                    <div class="pull-right">
                        <button @click.stop.prevent="submitFile($event, 'photo')" class="btn btn-info btn-sm">Upload</button>
                        <button class="btn btn-info btn-sm pull-right ml-2" @click="ui.showPhotoUpload = false">Cancel</button>
                    </div>
                </div>
            </sc-modal>
        </div>
    </div>

    <div v-if="files" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <div class="card sp-border-dark" >
            <div class="card-header sp-card-header">
                Files
            </div>
            <!-- /.panel-heading -->
            <div class="card-body">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item" v-for="(file) in fileSet" v-bind:key="file.file">
                        <div style="display: flex;justify-content: space-between;align-items: flex-start;align-content: stretch;">
                            <div style="flex: 1 1 auto;">
                                <a target="_blank" v-bind:href="dl_for_file(file.file)">{{file.file_name}}</a>
                                <br>
                                <span>
                                    <small>
                                        <strong>{{ !file.comment ? '(no comment)' : file.comment }}</strong>
                                        <br>
                                        <em>Uploaded: {{ file.uploaded_on | fromNow }}</em>
                                    </small>
                                </span>
                            </div>
                            <div style="flex: 0 1 auto;">
                                <div class="dropdown">
                                    <button class="btn btn-outline-primary btn-xs dropdown-toggle" data-toggle="dropdown"><i class="fa fa-bars"></i></button>
                                    <ul class="dropdown-menu">
                                        <li class="dropdown-item"><a v-bind:href="dl_for_file(file.file)" ><i class="fa fa-fw fa-search-plus"></i> Download</a></li>
                                        <li class="dropdown-item"><a href="#" @click.prevent="editComment(device, file)"><i class="fa fa-fw fa-edit"></i> Edit Comment</a></li>
                                        <li class="dropdown-item"><a href="#" @click.prevent="delPhoto(device, file)" ><i class="fa fa-fw fa-trash"></i> Delete</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <button @click="ui.showFileUpload = true" class="btn btn-info btn-sm pull-right mt-3">Add File</button>
            </div>
            <sc-modal v-if="ui.showFileUpload" @close="ui.showFileUpload = false">
                <span slot="title">Choose File</span>
                <div slot="body">
                    <div id="photo_panel_ul_div" >
                        <form enctype="multipart/form-data">
                            <div class="form-group">
                                <div class="custom-file">
                                    <input type="file" ref="file" class="custom-file-input" id="file" name="file" :accept="fileExtensions">
                                    <label class="custom-file-label" for="customFile">Choose file</label>                                    
                                    <div style="text-align: right; font-size: small; font-style: italic;">
                                        Supported extensions: {{ fileExtensions }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="sr-only" for="comment">Comment</label>
                                <input type="text" class="form-control" id="comment" name="comment" v-model="newFileComment" placeholder="Comment">
                            </div>

                        </form>
                    </div>
                </div>
                <div slot="footer" class="sp-modal-footer">
                    <div class="pull-right">
                    <button @click.stop.prevent="submitFile($event, 'general')" class="btn btn-info btn-sm">Upload</button>
                    <button class="btn btn-info btn-sm pull-right ml-2" @click="ui.showFileUpload = false">Cancel</button>
                </div>
                </div>
            </sc-modal>
        </div>
    </div>
</div>
</template>

<script>

export default {
        props: {
            device: Object,
            entity_type: {
                type:String,
                required:false,
                default:'device'
            },
            files: {
                type:Boolean,
                required:false,
                default: false
            },
            fileExtensions: {
                type: String,
                required:false,
                default: ''
            }
        },
        methods: {
            src_for_file: function(filename){
                return this.cfg.urls.src_with_args({filename: filename})
            },
            dl_for_file: function(filename){
                return this.cfg.urls.download_with_args({ filename: filename, entity_type: this.entity_type, oid: this.device._id })
            },
            editComment(device, photo){
                var newCmt = window.prompt("Edit this comment", photo.comment)
                if(newCmt){
                    var form = new FormData();
                    form.set('comment', newCmt);
                    axios({
                        method: 'post',
                        url: this.cfg.urls.edit_with_args({filename: photo.file}),
                        data: form,
                        config: { headers: {'Content-Type': 'multipart/form-data' }}
                        })
                        .then(function(){
                            photo.comment = newCmt
                        })
                }
            },
            makePrimary(device, photo){
                var form = new FormData();
                var vm = this;
                axios({
                    method: 'post',
                    url: this.cfg.urls.move_with_args({filename: photo.file, target_index: 0}),
                    data: form,
                    config: { headers: {'Content-Type': 'multipart/form-data' }}
                    })
                .then(function(){
                    var index = _.findIndex(vm.device.files, function(it){ return it.file == photo.file })
                    vm.device.files.splice(0, 0, vm.device.files.splice(index, 1)[0])
                })

            },
            delPhoto(device, photo){
                var vm = this;
                var index = _.findIndex(vm.device.files, function(it){ return it.file == photo.file })
                window.confirm("Do you really want to delete this file?")
                &&
                axios.post( vm.cfg.urls.delete_with_args({filename: photo.file, target_index: 0}))
                    .then(function(){
                        vm.device.files.splice(index, 1);
                    })
            },

            submitFile($event, file_type){
                var vm = this;
                let formData = new FormData();
                formData.append('file', this.$refs.file.files[0] );

                if(this.newFileComment){
                    formData.append('comment', this.newFileComment);
                }

                axios({
                        method: 'post',
                        url: this.cfg.urls.upload.replace('PLACEHOLDER:file_type', file_type),
                        data: formData,
                        config: { headers: {'Content-Type': 'multipart/form-data' }}
                        })
                    .then(function(resp){
                        if(! _.isArray(vm.device.files)){
                            Vue.set(vm.device, 'files', []);
                        }
                        vm.device.files.push(resp.data)
                        jQuery(vm.$refs.file).closest("form")[0].reset()
                        vm.newFileComment = null;
                        vm.ui.showPhotoUpload = false;
                        vm.ui.showFileUpload = false;
                    })
                    .catch(function(err){
                        alert(err)
                    })
            }
        }
        ,data: function(){
            return {
                newFileComment: null,
                cfg: {
                    urls: window.SMARTPLACES.urls.files.get_for(this.device._id, this.entity_type)
                },
                ui: {
                    showPhotoUpload: false,
                    showFileUpload: false
                }
            }
        }
        ,computed: {
            photoSet: function(){
                var vm = this;
                if(!vm.device.files){ return []; };
                return _(vm.device.files)
                            .filter( function(it){ return it.type == 'photo' })
                            .value()
            },
            fileSet: function(){
                var vm = this;
                if(!vm.device.files){ return []; };
                return _(vm.device.files)
                            .filter( function(it){ return it.type == 'general' })
                            .orderBy( "uploaded_on", ['desc'])
                            .value()
            }
        }
        ,
        watch: {
            'ui.showPhotoUpload': function(val){
                if(val){
                    setTimeout(function(){
                        bsCustomFileInput.init()
                    }, 1000)
                }
            }
            ,
            'ui.showFileUpload': function(val){
                if(val){
                    setTimeout(function(){
                        bsCustomFileInput.init()
                    }, 1000)
                }
            }
        }
    }


</script>

<style>
</style>
<template>
    <transition name="sc-modal">
        <div class="sc-modal-mask">
            <div class="sc-modal-wrapper">
                <div class="sc-modal-container" v-bind:class="size">

                    <div class="sc-modal-header">
                        <nav class="navbar-dark">
                            <div class="navbar-brand">
                                <slot name="title"></slot>
                            </div>
                        </nav>
                    </div>

                    <div class="sc-modal-body">
                        <slot name="body">
                            default body
                        </slot>
                    </div>

                    <div class="sc-modal-footer">
                        <slot name="footer">
                            <div class="text-right">
                                <button class="btn btn-info btn-sm" @click="$emit('close')">Close</button>
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        props: {
            size: { 
                type:String, 
                required:false, 
                default:''
            }
        }
    }
</script>

<style>
/* Modal windows used in Vue frontends */
.sc-modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(158, 158, 158, 0.74);
  display: table;
  transition: opacity .3s ease;
}

.sc-modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

@media only screen and (min-width: 0px) and (max-width: 600px)
{
  .sc-modal-container.lg {
    width: 100%;
    height: 95vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1000px)
{
  .sc-modal-container.lg {
    width: 80%;
    height: 90vh;
  }
}
@media only screen and (min-width: 1000px)
{
  .sc-modal-container.lg {
    width: 800px;
    height: 90vh;
  }
}

@media only screen and (min-width: 0px) and (max-width: 600px)
{
  .sc-modal-container.md {
    width: 95vw;
    height: 95vh;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1000px)
{
  .sc-modal-container.md {
    width: 80vw;
    height: 90vh;
  }
}
@media only screen and (min-width: 1000px)
{
  .sc-modal-container.md {
    width: 600px;
    height: 90vh;
  }
}


.sc-modal-container {
  width: 500px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 4px 6px 5px 2px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: column;
}

.sc-modal-header nav {
  padding-left: 30px;
}

.sc-modal-body {
  padding: 20px 30px;
  flex-grow: 1;
  overflow-y: auto;
}

.sc-modal-footer {
  padding: 10px;
  border-top: 1px solid darkslategray;
  flex-grow: 0;
  box-shadow: #e4e4e4 0 -5px 6px 0px
}

.sc-modal-default-button {
  
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.sc-modal-enter {
  opacity: 0;
}

.sc-modal-leave-active {
  opacity: 0;
}

.sc-modal-enter .sc-modal-container,
.sc-modal-leave-active .sc-modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/* -- END Modal Vue Frontend */
</style>
<template>
  <!-- Carpark Scheme devices -->
  <div class="editor-device-carpark">
    <fieldset style="margin-top:40px;">
      <legend>Car Parks</legend>
      <span class="text-danger" v-text="errors['carpark']"></span>
      <div
        class="row"
        v-if="device.carpark && typeof device.carpark.counter1 !== 'undefined'"
      >
        <div class="col-sm-12 col-md-4">
          <br />
          Counter 1:
          <span
            v-if="
              device.carpark && typeof device.carpark.counter1 !== 'undefined'
            "
          >
            <select class="form-control" v-model="device.carpark.counter1">
              <option :value="'in'" :key="'in'">In</option>
              <option :value="'out'" :key="'out'">Out</option>
              <option :value="''" :key="''">(Ignore)</option>
            </select>
          </span>
          <span class="text-danger" v-text="errors['carpark.counter1']"></span>
          <br />
          Counter 2:
          <span
            v-if="
              device.carpark && typeof device.carpark.counter2 !== 'undefined'
            "
          >
            <select class="form-control" v-model="device.carpark.counter2">
              <option :value="'in'" :key="'in'">In</option>
              <option :value="'out'" :key="'out'">Out</option>
              <option :value="''" :key="''">(Ignore)</option>
            </select>
          </span>
          <span class="text-danger" v-text="errors['carpark.counter2']"></span>
          <br />
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  props: ["device", "errors"],
  data: function() {
    return {};
  },
};
</script>

<style></style>

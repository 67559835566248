<template>
    <div class="row ml-2 mt-3">
        <strong class="mr-2 mt-1">Select Time Period:</strong>
        <div class="btn-group" role="group">
        <!-- using the built-in data object, set the period into the shared config -->
            <button v-blur @click="setMode('day')" type="button" class="btn" v-bind:class="cfg.mode == 'day' ? 'btn-primary':'btn-light'" style="flex-grow: 1">Day</button>
            <button v-blur @click="setMode('week')" type="button" class="btn" v-bind:class="cfg.mode == 'week' ? 'btn-primary':'btn-light'" style="flex-grow: 1">Week</button>
            <button v-blur @click="setMode('month')" type="button" class="btn" v-bind:class="cfg.mode == 'month' ? 'btn-primary':'btn-light'" style="flex-grow: 1">Month</button>
            <button v-blur @click="cfg.shift(-1)" type="button" class="btn btn-light">Previous {{cfg.mode}}</button>
            <button v-blur @click="cfg.shift(+1)" type="button" class="btn btn-light">Next {{cfg.mode}}</button>
        </div>
    </div>
</template>

<script>
export default {
        props: ['cfg'], // a PSP.DateFunctions.DateRangeCfg() // defined in: webapp/static/components/date_component_functions.js
        data: function(){
            return {
            }
        },
        methods: {
            setMode: function(input){
                this.cfg.setMode(input)
            }
        }
}
</script>
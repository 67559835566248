<template>
    <span class="ts-val-wrapper badge text-white" v-bind:class="{nodata: !hasdata, 'badge-primary':hasdata, 'badge-default':!hasdata}"><span class="ts-val">{{ value }}{{ suffix }}</span><span class="nodata-label"><em>No Data</em></span></span>
</template>

<script>
    export default {
        props: ['device', 'agg', 'over', 'field', 'scheme', 'suffix']
        ,
        methods: {
            
        }
        ,
        data: function(){
            return {
                value: null
                ,hasdata: false
            }
        }
        ,
        mounted: function(){
            var vm = this;
            var urls = window.SMARTPLACES.urls[vm.scheme].get_for(vm.device)
            axios.get(urls.feed_latest + "?agg=" + vm.agg + "&over=" + vm.over).then(function(resp){
                if(resp.data && resp.data.length > 0) {
                    for(var i=0;i<resp.data.length;i++){
                        var res = resp.data[i]
                        if(res[vm.field] && typeof(res[vm.field]) == 'number' ){
                            vm.value=GLOBALS.round(res[vm.field], 2)
                            vm.hasdata = true;
                        }
                    }
                }
                else {
                    vm.hasdata = false;
                }
            })
        }
    }
</script>

<style>
    span.ts-val-wrapper {
        font-size: 1.1em;
    }

    span.ts-val-wrapper.nodata > span.ts-val {
        display: none;
    }

    span.ts-val-wrapper > span.nodata-label {
        display:none;
    }

    span.ts-val-wrapper.nodata > span.nodata-label {
        display: inline;
    }
</style>